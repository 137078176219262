/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import Landing from './landing';

export default function Index() {
  return (
    <>
      <Landing />
    </>
  );
}
